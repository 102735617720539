import React from 'react';
import { fetchGraphQLQuery } from '../../../../../graphql/service';
import {
  GetDrivingReportDocument,
  GetDrivingReportQuery,
  GetDrivingReportQueryVariables,
  RunDrivingHistoryReportDocument,
  RunDrivingHistoryReportMutation,
  RunDrivingHistoryReportMutationVariables,
} from '../../../../../graphql/api';
import {
  DrivingReport,
  GetDrivingHistory,
  RunDrivingHistoryCheck,
} from './types';

const toDrivingReport = (
  report:
    | RunDrivingHistoryReportMutation['runDrivingCheck']
    | GetDrivingReportQuery['getDrivingReport'][number],
): DrivingReport => {
  if (report.__typename === 'DrivingReportSuccess') {
    return {
      isAvailable: true,
      reportedAt: report.reportedAt,
      violations: report.violations.map(v => ({
        acd: v.avd,
        date: v.date,
        avd: v.avd,
        description: v.description,
        severity: v.severity,
      })),
    };
  }

  if (report.__typename === 'DrivingReportFailure') {
    return {
      isAvailable: false,
      description: report.description,
      reason: report.reason,
    };
  }
  return {
    isAvailable: false,
    description: 'Unknown description',
    reason: 'Unknown reason',
  };
};

class DrivingHistoryApi {
  constructor(private readonly userId: string) {}

  public getDrivingHistory = async (): Promise<GetDrivingHistory> => {
    try {
      const response = await fetchGraphQLQuery<
        GetDrivingReportQuery,
        GetDrivingReportQueryVariables
      >(GetDrivingReportDocument, {
        userId: this.userId,
      });

      return {
        success: true,
        data: {
          drivingReports: response.getDrivingReport.map(toDrivingReport),
        },
      };
    } catch (e: any) {
      return {
        success: false,
        error: e.message,
      };
    }
  };

  public runDrivingHistoryCheck = async (
    note: string,
  ): Promise<RunDrivingHistoryCheck> => {
    try {
      const response = await fetchGraphQLQuery<
        RunDrivingHistoryReportMutation,
        RunDrivingHistoryReportMutationVariables
      >(RunDrivingHistoryReportDocument, {
        input: {
          userId: this.userId,
          forceDetailedCheck: true,
          note,
        },
      });

      return {
        success: true,
        data: {
          drivingReport: toDrivingReport(response.runDrivingCheck),
        },
      };
    } catch (e: any) {
      return {
        success: false,
        error: e.message,
      };
    }
  };
}

const useApi = (userId: string) => {
  const api = React.useMemo(() => new DrivingHistoryApi(userId), [userId]);

  return api;
};

export default useApi;
