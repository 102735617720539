import { SearchPoliciesQuery } from '../../../../graphql/api';
import { Policy } from './types';
import { Interval } from 'luxon';

export const useSearchPoliciesSelector = ({
  policies,
}: SearchPoliciesQuery): Policy[] => {
  const dateFormat = 'd LLL yyyy HH:mm:ss';

  return policies.map(policy => {
    const { start, end } = Interval.fromISO(policy.term, { setZone: true });

    return {
      policyNumber: policy.policyNumberDetails.value,
      effectiveDate: start.toFormat(dateFormat),
      expirationDate: end.toFormat(dateFormat),
      userId: `${policy.policyholderId}`,
      policyholder: `${policy.policyholder.name.firstName} ${policy.policyholder.name.lastName}`,
      vehicles: '',
      status: policy.status.toLowerCase(),
    };
  });
};
