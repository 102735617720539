import parsePhoneNumber from 'libphonenumber-js';
import { DateTime } from 'luxon';
import { GetUserSummaryQuery } from '@graphql/types';
import { UserSummary } from './types';
import { sortPoliciesByDate } from '@helpers/policyHelpers';

const getUserSummarySelector = ({
  user,
}: GetUserSummaryQuery): UserSummary | undefined => {
  if (user.policies.length === 0) {
    return undefined;
  }

  const userPolicies = sortPoliciesByDate(user.policies);
  const phoneNumber = user.phoneNumber
    ? parsePhoneNumber(user.phoneNumber)?.formatNational()
    : undefined;
  const dob = DateTime.fromISO(userPolicies[0].policyholder.dob);

  return {
    phoneNumber: phoneNumber ?? 'No phone number',
    dob: dob.toFormat('MM/dd/yyyy'),
    address: Object.values(userPolicies[0].policyholder.address)
      .filter(value => !!value)
      .join(', '),
    driverLicenseNumber:
      userPolicies[0].quoteV2.insuranceApplication?.personalDetails
        .driverLicense?.number ?? 'No driver license number',
    autoTripEnabled:
      user.betaFlagContent.flags?.find(flags => flags.key === 'isAutoTrip')
        ?.value ?? false,
    autoPayEnabled: user.wallet.automaticPayments?.isEnabled ?? false,
  };
};

export default getUserSummarySelector;
