import { getConfig, getCurrentEnv, getCurrentVersion } from '../config';
import { v4 as uuidv4 } from 'uuid';
import { getJustToken } from '../services/auth';

const config = getConfig();

function createXHeaders(): Record<string, string | number> {
  const correlationId = uuidv4();
  const env = getCurrentEnv();
  const version = getCurrentVersion();

  return {
    'x-correlation-id': correlationId,
    'x-app-id': `just-portal-${env}`,
    'x-app-version': version,
    'x-app-build-number': 99999,
  };
}

export const fetchData = <TData, TVariables>(
  query: string,
  variables?: TVariables,
): (() => Promise<TData>) => {
  return async () => {
    const token = await getJustToken();
    const xHeaders = createXHeaders();
    const idempotencyKey = uuidv4();
    const res = await fetch(`${config.REACT_APP_BASE_SERVICE_URL}/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'Idempotency-Key': idempotencyKey,
        ...xHeaders,
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0] || 'Error..';
      throw new Error(message);
    }

    return json.data;
  };
};
