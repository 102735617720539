export const policyListColumnDefs = [
  {
    id: 'policyNumber',
    title: 'Policy Number',
    sortable: false,
  },
  {
    id: 'effectiveDate',
    title: 'Effective Date',
    sortable: false,
  },
  {
    id: 'expirationDate',
    title: 'Expiration Date',
    sortable: false,
  },
  {
    id: 'userId',
    title: 'User Id',
    sortable: false,
  },
  {
    id: 'policyholder',
    title: 'Policyholder',
    sortable: false,
  },
  {
    id: 'status',
    title: 'Policy Status',
    sortable: false,
  },
  {
    id: 'link',
    title: 'Radr link',
    sortable: false,
  },
];
