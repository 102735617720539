import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { getSelectedCoverages } from '../../../helpers/quoteHelpers';
import { Quote } from '../../../services/repository/userService';
import { MenuItem, PopUpMenu } from '../../../components/PopUpMenu/PopUpMenu';
import { QuoteOverviewStatus } from '../../../graphql/api';
import { PopUpMenuContainer } from './QuoteList.styled';

interface Props {
  item: Quote;
  onSelectQuote: (quote: Quote) => void;
  onMenuClick: (quoteId: number) => void;
}

enum PolicyType {
  REFRESH = 'Short-term',
  RENEW = 'Standard',
  FIXED_PRICE = 'Fixed',
}

enum RowOptions {
  IssuePolicyEndorsement = 'IssuePolicyEndorsement',
}

const menuItems: MenuItem[] = [
  {
    label: 'Issue policy endorsement',
    type: RowOptions.IssuePolicyEndorsement,
  },
];

export const QuoteRowTemplate: React.FC<Props> = ({
  item,
  onSelectQuote,
  onMenuClick,
}) => {
  const selectedCoverages = getSelectedCoverages(item.price, item.selection);
  const policyType = item.price.product.type;
  const quoteId = item.id;
  return (
    <TableRow className="policy">
      <TableCell>
        <div
          className="clickable"
          title="Click here to view the quote"
          onClick={() => onSelectQuote(item)}
        >
          {item.id}
        </div>
      </TableCell>
      <TableCell>{item.issueDate}</TableCell>
      <TableCell>
        {policyType in PolicyType ? PolicyType[policyType] : policyType}
      </TableCell>
      <TableCell>
        <div>
          {!selectedCoverages ? <div>No selection</div> : null}
          {selectedCoverages ? <div>{selectedCoverages.join(', ')}</div> : null}
        </div>
      </TableCell>
      <TableCell>
        <div>
          {`${item.selectedPrice?.pricePerMile || 0}c per mile`}
          <br />
          {`${item.selectedPrice?.pricePerDay || 0}c per day`}
        </div>
      </TableCell>
      <TableCell>{item.isAccepted ? 'YES' : 'NO'}</TableCell>
      <TableCell>{item.acceptedAt}</TableCell>
      <TableCell>{item.status}</TableCell>
      <TableCell>
        {item.status === QuoteOverviewStatus.Active && quoteId ? (
          <PopUpMenuContainer>
            <PopUpMenu
              menuItems={menuItems}
              onClick={() => onMenuClick(quoteId)}
            />
          </PopUpMenuContainer>
        ) : null}
      </TableCell>
    </TableRow>
  );
};
