import React, { useContext, useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  User,
} from 'firebase/auth';
import { getConfig } from '@config';
import { ErrorCode, logError } from '@services/logging';

type FirebaseAuthContext = {
  ready: boolean;
  authenticated: boolean;
  user: AuthRadrUser | null;
  signOut: () => Promise<void>;
  signInWithGoogle: () => Promise<ActionResult<undefined, string>>;
  getJustToken: (force?: boolean) => Promise<string | null>;
};

const Context = React.createContext<FirebaseAuthContext | undefined>(undefined);

export const AuthAppController: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [auth] = useState(() => {
    const firebaseConfig = JSON.parse(
      atob(getConfig().REACT_APP_FIREBASE_CONFIG_BASE64),
    );
    initializeApp(firebaseConfig);
    return getAuth();
  });
  const [googleProvider] = useState(() => {
    const provider = new GoogleAuthProvider();
    // make sure we always ask to pick account
    provider.setCustomParameters({ prompt: 'select_account' });
    return provider;
  });
  const [ready, setReady] = useState(false);
  const [user, setUser] = useState<AuthRadrUser | null>(null);

  async function signOut() {
    await auth.signOut();
  }

  async function signInWithGoogle(): Promise<ActionResult<undefined, string>> {
    try {
      await signInWithPopup(auth, googleProvider);
      return { success: true };
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);

      logError(ErrorCode.UserV2, error, {
        errorCode,
        email,
        credential: (credential as string | null) || '',
      });
      return { success: false, error: `Cannot sign in: ${errorMessage}` };
    }
  }

  async function getJustToken(force?: boolean): Promise<string | null> {
    if (!auth.currentUser) {
      return null;
    }

    try {
      const result = await auth.currentUser.getIdTokenResult(force);
      return result.token || null;
    } catch (error: any) {
      logError(ErrorCode.UserV2, error);
      return null;
    }
  }

  useEffect(() => {
    auth.authStateReady().then(() => setReady(true));
    auth.onIdTokenChanged(updated => {
      setUser(toUser(updated));
    });
  }, []);

  return (
    <Context.Provider
      value={{
        ready,
        authenticated: !!user,
        user,
        signOut,
        signInWithGoogle,
        getJustToken,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export function useAppAuth(): FirebaseAuthContext {
  const context = useContext(Context);
  if (!context) {
    throw new Error('App Auth context is missing!');
  }
  return context;
}

function toUser(user: User | null | undefined): AuthRadrUser | null {
  if (!user) {
    return null;
  }
  return {
    email: user.email,
    uid: user.uid,
    name: user.displayName,
    photoUrl: user.photoURL,
  };
}
