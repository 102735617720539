import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import style, { Container, Logo } from './Authentication.styled';
import { getCurrentEnv } from '../../../../config';
import { useAppAuth } from '../../../../components/FirebaseAuth';

const Authentication: React.FC = () => {
  const { signInWithGoogle } = useAppAuth();

  async function logInWithGoogle() {
    const result = await signInWithGoogle();
    if (!result.success) {
      alert(`Could not sign in: ${result.error}`);
    }
  }

  return (
    <Container>
      <div>
        <Typography align="center" color="primary" variant="h2">
          Just Insure Portal
        </Typography>
        <Typography align="center" color="primary" variant="h5">
          Easy to understand. Easy to get.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={logInWithGoogle}
        >
          Login
        </Button>
      </div>

      <Logo src={`/${getCurrentEnv()}/logo.png`} alt="" />
    </Container>
  );
};

export default withStyles(style)(Authentication);
