import { jwtDecode } from 'jwt-decode';

type GetJustAuthToken = (force?: boolean) => Promise<string | null>;

let getJustTokenBlock: GetJustAuthToken | null = null;

export function setGetJustToken(block: GetJustAuthToken) {
  getJustTokenBlock = block;
}

export const getJustToken = async (): Promise<string | null> => {
  if (!getJustTokenBlock) {
    return null;
  }

  let token = await getJustTokenBlock();

  if (!token) {
    return null;
  }

  if (isTokenExpired(token)) {
    // if id token is expired, we need to force refresh it
    // this is done via omitting cache in access token request
    token = await getJustTokenBlock(true);
  }

  if (!token) {
    return null;
  }

  return token;
};

function isTokenExpired(token: string): boolean {
  let expires: number | null = null;
  try {
    const result = jwtDecode<{ exp: number }>(token);
    expires = result.exp * 1000;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    return false;
  }
  if (!expires) {
    return false;
  }

  const currentTimestamp = Date.now();
  return expires - currentTimestamp <= 0;
}
