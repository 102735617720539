import numeral from 'numeral';
import React, { ComponentType, FC, useEffect } from 'react';
import { Redirect, Route, HashRouter, Switch } from 'react-router-dom';
import Portal from './features/portal/Portal/Portal';
import { QueryClient } from 'react-query';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { Duration } from 'luxon';
import { useState } from 'react';
import { setGetJustToken } from './services/auth';
import { createBrowserHistory } from 'history';
import Authentication from './features/authentication/components/Authentication/Authentication';
import { withErrorBoundary } from './components/withErrorBoundary';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { PageLoader } from './components/PageLoader';
import { getCurrentEnv, Environment } from './config';
import { setSentryUser } from './services/logging';
import { default as PortalV2 } from './v2/Portal';
import { useAppAuth } from './components/FirebaseAuth';

export const history = createBrowserHistory();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Duration.fromObject({ day: 1 }).as('seconds'),
      useErrorBoundary: true,
    },
  },
});

const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
});

// register the locale
numeral.register('locale', 'us', {
  abbreviations: {
    billion: 'b',
    million: 'm',
    thousand: 'k',
    trillion: 't',
  },
  currency: {
    symbol: '$',
  },
  delimiters: {
    decimal: '.',
    thousands: ',',
  },
} as any);

numeral.locale('us');

const App: React.FC = () => {
  const { ready, user, getJustToken, authenticated } = useAppAuth();
  setGetJustToken(getJustToken);

  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    persistQueryClient({
      queryClient,
      persistor: localStoragePersistor,
    })
      .then(() => setIsReady(true))
      // eslint-disable-next-line no-console
      .catch(err => console.error(err));
  });

  useEffect(() => {
    const isStaging = getCurrentEnv() === Environment.Staging;

    if (isStaging) {
      const link = document.getElementById('favicon') as HTMLLinkElement;
      if (link) {
        link.href = link.href.replace(
          `/${Environment.Production}/`,
          `/${Environment.Staging}/`,
        );
      }
    }
  }, []);

  useEffect(() => {
    setSentryUser(user?.email ?? undefined);
  }, [user]);

  if (!ready || !isReady) {
    return <PageLoader text="Loading Radr" />;
  }

  if (!authenticated) {
    return <Authentication />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <HashRouter>
        <Switch>
          <Route path="/v2/" component={withAuthenticationRequired(PortalV2)} />
          <Route path="/" component={withAuthenticationRequired(Portal)} />
          <Redirect to="/" />
        </Switch>
      </HashRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

const withAuthenticationRequired = <P extends object>(
  Component: ComponentType<P>,
): FC<P> => {
  return function WithAuthenticationRequired(props: P): JSX.Element {
    const { authenticated, ready } = useAppAuth();

    if (!ready) {
      return <p>Loading</p>;
    }

    return authenticated ? <Component {...props} /> : <p>401 NOT AUTHORIZED</p>;
  };
};

export default withErrorBoundary(App);
